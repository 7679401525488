<template>

  <div class="section-body mb-5">
    <head-slot>
      <title>Afghanistan Cricket Board | Users</title>
    </head-slot>

    <form action="#" method="post" enctype="multipart/form-data" id="userForm" @submit.prevent="saveUser">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="card user">
            <div class="card-header">
              <h4>User</h4>
            </div>
            <div class="card-body">

              <img :src="$store.state.Global.rootCDN + user.photo" alt="" style="height: 120px;" v-if="user.id">
              <div class="form-group">
                <label for="name">Name</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <input type="text" class="form-control" name="name" id="name" v-model="user.name">

                  <input type="hidden" class="form-control" name="user_id" id="user_id" v-model="user.id"
                         v-if="user.id">

                </div>
                <span class="text text-danger" v-if="validationErrors.name">
                    {{ validationErrors.name[0] }}
                </span>
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-mail-bulk"></i>
                    </div>
                  </div>
                  <input type="email" class="form-control" id="email" name="email" v-model="user.email">
                </div>
                <span class="text text-danger" v-if="validationErrors.email">
                    {{ validationErrors.email[0] }}
                </span>
              </div>
              <div class="form-group" v-if="!user.id">
                <label for="password">Password</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-lock"></i>
                    </div>
                  </div>
                  <input type="password" class="form-control pwstrength" data-indicator="pwindicator" id="password"
                         name="password">
                </div>
                <span class="text text-danger" v-if="validationErrors.password">
                    {{ validationErrors.password[0] }}
                </span>
              </div>
              <div class="form-group" v-if="!user.id">
                <label for="password_confirmation">Confirm Password</label>
                <div class="input-group">

                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-lock"></i>
                    </div>
                  </div>

                  <input type="password" class="form-control pwstrength" data-indicator="pwindicator"
                         name="password_confirmation" id="password_confirmation">
                </div>

              </div>
              <div class="form-group">
                <label for="name">Photo</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-image"></i>
                    </div>
                  </div>
                  <input type="file" class="form-control" name="photo" id="photo">
                  <input type="hidden" class="form-control" name="old_photo" id="old_photo" v-model="user.photo">
                </div>
                <span class="text text-danger" v-if="validationErrors.photo">
                    {{ validationErrors.photo[0] }}
                </span>
              </div>

              <div class="row">
                <div class="col-6">
                  <button type="submit" class="btn btn-primary btn-block">Save</button>
                </div>
                <div class="col-6" v-if="user.id">
                  <button type="reset" class="btn btn-danger btn-block" @click="user = {}; domains = []">Cancel</button>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="card user-access-area">
            <div class="card-header">
              <h4> User Access Areas </h4>
            </div>
            <div class="card-body">
              <div class="form-group">

                <label class="form-label">Admin</label>
                <div class="selectgroup selectgroup-pills">
                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="admin" class="selectgroup-input"
                           :checked="findDomain('admin')">
                    <span class="selectgroup-button">Admin</span>
                  </label>
                </div>

                <label class="form-label">Dashboard</label>
                <div class="selectgroup selectgroup-pills">
                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="dashboard" class="selectgroup-input"
                           :checked="findDomain('dashboard')">
                    <span class="selectgroup-button">Dashboard</span>
                  </label>

                </div>

              </div>
              <div class="form-group">

                <label class="form-label">Users</label>
                <div class="selectgroup selectgroup-pills">

                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="users" class="selectgroup-input"
                           :checked="findDomain('users')">
                    <span class="selectgroup-button">Users</span>
                  </label>

                </div>

              </div>
              <div class="form-group">

                <label class="form-label">Scoring and Performance</label>
                <div class="selectgroup selectgroup-pills">

                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="scoring-and-performance-lookup"
                           class="selectgroup-input"
                           :checked="findDomain('scoring-and-performance-lookup')">
                    <span class="selectgroup-button"> Lookup</span>
                  </label>


                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="scoring-and-performance-manage-teams"
                           class="selectgroup-input"
                           :checked="findDomain('scoring-and-performance-manage-teams')">
                    <span class="selectgroup-button"> Manage Teams</span>
                  </label>
                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="scoring-and-performance-manage-players"
                           class="selectgroup-input"
                           :checked="findDomain('scoring-and-performance-manage-players')">
                    <span class="selectgroup-button"> Manage Players</span>
                  </label>
                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="scoring-and-performance-set-tournament"
                           class="selectgroup-input"
                           :checked="findDomain('scoring-and-performance-set-tournament')">
                    <span class="selectgroup-button"> Set Tournament</span>
                  </label>

                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="scoring-and-performance-create-fixture"
                           class="selectgroup-input"
                           :checked="findDomain('scoring-and-performance-create-fixture')">
                    <span class="selectgroup-button"> Create Fixture</span>
                  </label>


                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="scoring-and-performance-launch-match"
                           class="selectgroup-input"
                           :checked="findDomain('scoring-and-performance-launch-match')">
                    <span class="selectgroup-button"> Launch Match </span>
                  </label>


                </div>

              </div>

              <div class="form-group">

                <label class="form-label">Reports</label>
                <div class="selectgroup selectgroup-pills">

                  <label class="selectgroup-item">
                    <input type="checkbox" name="domain[]" value="report-top-performers"
                           class="selectgroup-input"
                           :checked="findDomain('report-top-performers')">
                    <span class="selectgroup-button">Top Performers</span>
                  </label>

                </div>
              </div>


            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>Users Table</h4>
              <div class="card-header-form">
                <form>
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search">
                    <div class="input-group-btn">
                      <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table table-striped table-">
                  <tbody>
                  <tr>


                    <th>ID</th>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th class="text-center">Action</th>

                  </tr>
                  <tr v-for="user in users" :key="user.id">
                    <td>{{ user.id }}</td>

                    <td>
                      <img alt="image" :src="$store.state.Global.rootCDN+user.photo" class="rounded-circle" width="35"
                           data-toggle="tooltip" title="" data-original-title="Wildan Ahdian">

                    </td>
                    <td>{{ user.name }}</td>

                    <td>
                      {{ user.email }}
                    </td>


                    <td class="text-center">
                      <a href="#" class="btn btn-danger" @click.prevent="deleteUser(user.id)">
                        <i class="fa fa-trash"></i>
                        Delete
                      </a>
                      <a href="#" class="btn btn-primary" @click.prevent="editUser(user.id)">
                        <i class="fa fa-edit"></i>
                        Edit
                      </a>
                      <a href="#" class="btn btn-warning" data-toggle="modal" data-target="#exampleModal"
                         @click.prevent="newPassword.user_id = user.id">
                        <i class="fa fa-key"></i>
                        ChangePassword
                      </a>
                      <!--                      <a href="#" class="btn btn-dark" @click.prevent="loginID(user.id)">-->
                      <!--                        <i class="fa fa-sign-in-alt"></i>-->
                      <!--                        Login-->
                      <!--                      </a>-->
                    </td>
                  </tr>

                  </tbody>
                </table>
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="formModal"
                     aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="formModal">Change Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <form class="" method="post" @submit.prevent="changePassword">

                          <div class="form-group">
                            <label for="new_password">Password</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div class="input-group-text">
                                  <i class="fas fa-lock"></i>
                                </div>
                              </div>
                              <input type="password" class="form-control" placeholder="New Password" name="new_password"
                                     v-model="newPassword.password"
                                     id="new_password">
                            </div>
                          </div>

                          <button type="submit" class="btn btn-primary m-t-15 waves-effect btn-block"
                          >
                            Change Password
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </form>


  </div>
</template>

<script>
import {destroy, edit, loadUsers, loginWithID, newPassword, save, setToken, update} from "@/services/User";
import {useToast} from "vue-toastification";

export default {

  data() {
    return {
      newPassword: {

        user_id: '',
        password: '',

      },
      user: {},
      domains: [],
      users: [],
      validationErrors: [],
    }
  },
  methods: {
    getUsers() {
      loadUsers().then(response => {

        this.user = {};
        this.users = response.data;

      });
    },
    setError(error) {
      const toast = useToast();

      toast.clear()
      if (error.response.status === 422) {


        this.validationErrors = error.response.data.errors;
        toast.error(error.response.data.message);

      } else {

        toast.error('Something went wrong, please contact webmaster.')
      }
    },
    saveUser() {

      const toast = useToast();

      let form = document.getElementById('userForm');

      let data = new FormData(form);

      toast.info('processing...')

      if (!this.user.id) {
        save(data).then(() => {

          toast.clear()

          toast.success('User Successfully Registered!')

          form.reset();

          this.getUsers();

        }, error => {

          this.setError(error);

        });
      } else {
        update(data).then(() => {

          toast.clear()

          toast.success('User Successfully Registered!')

          form.reset();

          this.getUsers();
        }, error => {

          this.setError(error);
        });
      }


    },

    deleteUser(id) {

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {

          destroy(id).then(() => {
            this.getUsers();
          });

          this.$swal(
              'Deleted!',

              'User has been deleted.',

              'success'
          )

        }

      });

    },
    editUser(id) {
      edit(id).then(response => {
        this.user = response.data.user;
        this.domains = response.data.domains;

        console.log(response);

      });
    },

    findDomain(domain) {
      let d = this.domains.find(x => x.domain === domain);

      return !!d;


    },
    changePassword() {

      const toast = useToast();


      toast.info('processing...')

      newPassword(this.newPassword).then(() => {

        toast.clear();
        toast.success('User Password Successfully Changed!');

        this.newPassword.user_id = '';
        this.newPassword.password = '';

      }, () => {
        toast.clear();
        toast.error('Error');
      });
    },
    loginID(id) {
      let data = {'id': id};

      loginWithID(data).then(response => {


        this.$store.commit('Auth/user', response.data);
        this.$store.commit('Auth/login', true);

        setToken(7200000);
        this.$router.push('/')


      }, error => {

        this.setError(error)

      });


    }
  },
  mounted() {
    this.getUsers();
  }
}
</script>
<style scoped>
@media only screen and (min-width: 600px) {
  .user-access-area, .user {
    height: 597px !important;
    overflow: auto;
  }

}

</style>